<template>
  <div>
    <div v-show="addedItemToCart" class="add-item-success">
      <label>Item successfully added to cart</label>
    </div>
    <p v-if="getSelectedProduct.limited" class="inventory label warning">
      {{ getSelectedProduct.limitedQty }} left in stock
    </p>
    <p v-if="getSelectedProduct.minQtyForPurchase > 1" class="inventory label">
      This product has a minimum order quantity of {{ getSelectedProduct.minQtyForPurchase }}
    </p>
    <form v-if="!is503aProduct" class="add-to-cart-form">
      <div class="input">
        <p class="label">Quantity</p>
        <BaseInput
          v-model.number="quantity"
          type="number"
          :min="getSelectedProduct.minQtyForPurchase"
          :max="maxAvailable"
          :is-disabled="productLoading"
        />
      </div>
      <div class="submit">
        <p class="label">${{ getSelectedProduct.price.toFixed(2) }}</p>
        <BaseButton
          class="p-x-5"
          type="primary"
          :button-text="buttonText"
          :is-loading="productLoading"
          :is-disabled="isDisabled"
          :handle-click="addItemToCart"
        />
      </div>
    </form>
    <div v-else class="product-card--prescription-info">
      This product is only available on the Prescription page in the Patient Chart
    </div>
    <PatientLocationInfo v-if="patientLocation" @toggleAddLocation="toggleAddLocation" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { get } from "lodash";
import BaseInput from "@/components/common/BaseInput.vue";
import BaseButton from "@/components/common/AntDesign/BaseButton.vue";
import PatientLocationInfo from "@/components/common/PatientLocationInfo.vue";

export default {
  name: "ProductAddToCart",
  components: {
    BaseInput,
    BaseButton,
    PatientLocationInfo,
  },
  props: {
    contentColor: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      productLoading: false,
      addedItemToCart: false,
      patientLocation: false,
      cartProducts: [],
      // isPatient: 'false',
      userType: "",
    };
  },
  computed: {
    ...mapGetters({
      getAddToCartQty: "marketplaceProduct/getAddToCartQty",
      getOrgId: "getOrgId",
    }),
    ...mapGetters("locationsModule", ["getCurrentLocationSelected"]),
    ...mapGetters("productsModule", ["getSelectedProduct"]),
    quantity: {
      get() {
        return get(this, "getAddToCartQty", 1);
      },
      async set(qty) {
        await this.commitQty(Math.abs(qty));
      },
    },
    maxAvailable() {
      if (this.getSelectedProduct.limited) {
        return this.getSelectedProduct.limitedQty;
      }
      return 99999;
    },
    isDisabled() {
      const { minQtyForPurchase, limitedQty } = this.getSelectedProduct;
      if (this.quantity < minQtyForPurchase || (limitedQty && this.quantity > this.maxAvailable)) {
        return true;
      }
      return false;
    },
    is503aProduct() {
      return this.getSelectedProduct.subcategorySlug === "503A";
    },
    buttonText() {
      if (this.getSelectedProduct.isPreorder) return "Pre-Order";
      return "Add to cart";
    },
  },
  created() {
    const { minQtyForPurchase } = this.getSelectedProduct;
    this.quantity = minQtyForPurchase !== 0 ? minQtyForPurchase : 1;
  },
  methods: {
    ...mapActions({
      commitQty: "marketplaceProduct/commitSetAddToCartQty",
      setEmptyCart: "setEmptyCart",
      addToCartForList: "marketplaceProduct/addToCartForList",
    }),
    ...mapActions("ordersModule", ["addProductToMarketplaceCart", "addPreOrderToCart", ""]),
    async addProductToPreOrderCart() {
      this.productLoading = true;
      const orgId = this.getOrgId;
      const locationId = this.getCurrentLocationSelected;

      await this.addPreOrderToCart({
        orgId,
        locationId,
        payload: {
          productId: this.getSelectedProduct.id,
          qty: this.getAddToCartQty,
        },
      });
      this.productLoading = false;
    },
    async addItemToCart() {
      this.productLoading = true;
      if (this.getSelectedProduct.isPreorder) {
        await this.addProductToPreOrderCart();
      } else {
        const locationId = this.getCurrentLocationSelected;
        await this.addProductToMarketplaceCart({
          locationId,
          payload: {
            productId: this.getSelectedProduct.id,
            qty: this.getAddToCartQty,
          },
        });
        this.productLoading = false;
      }
    },
    toggleAddLocation() {
      this.patientLocation = !this.patientLocation;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.add-item-success {
  background-color: #e8f8cd;
  color: #67c23a;
  margin-bottom: 30px;
  padding: 10px;
  width: 300px;
  border-radius: 5px;
}
.add-to-cart-form {
  display: flex;
}
.label {
  font-size: 0.8em;
  margin-bottom: 5px;
}
.inventory {
  margin-bottom: 15px;
}
.warning {
  font-weight: 600;
  color: $alert-red;
}
.input {
  width: 100px;
  margin-right: 10px;
}
</style>
