<template>
  <div>
    <p v-if="product.limited" class="inventory label warning">{{ product.limitedQty }} left in stock</p>
    <p v-if="product.minQtyForPurchase > 1" class="inventory label">
      This product has a minimum order quantity of
      {{ product.minQtyForPurchase }}
    </p>
    <ProductSignIn v-if="!isLoggedIn" />
    <form v-else-if="!requireRegistration" class="add-to-cart-form">
      <div class="input">
        <p class="label">Quantity</p>
        <BaseInput
          v-model="quantity"
          type="number"
          :disabled="!product.inStock || productLoading"
          :min="product.minQtyForPurchase"
          :max="maxAvailable"
        />
      </div>
      <div class="submit">
        <p class="label">${{ product.price.toFixed(2) }}</p>
        <BaseButton
          v-if="!isNotify"
          class="p-x-5"
          type="primary"
          :button-text="buttonText"
          :is-loading="productLoading"
          :handle-click="addItemToCart"
        />
        <div v-else class="success-wrapper">
          <p class="success">
            Thank you for subscribing to stock notifications!<br />
            You will be emailed when this product is back in stock.
          </p>
        </div>
      </div>
      <!-- </template> -->
    </form>
    <BaseButton
      v-else
      class="p-x-5 apply-registration-btn"
      type="primary"
      button-text="Apply for registration"
      :handle-click="applyRegistrationModalToggle"
    />
    <PatientLocationInfo v-if="patientLocation" @toggleAddLocation="toggleAddLocation" />

    <!-- REGISTRATION MODAL -->
    <ApplyRegistrationModal
      v-if="applyRegistrationModalEnabled"
      :product="product"
      :apply-registration-modal-toggle="applyRegistrationModalToggle"
      :apply-registration-modal-enabled="applyRegistrationModalEnabled"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Message } from "element-ui";
import { get } from "lodash";
import VueCookies from "vue-cookies";
import jwtdecode from "jwt-decode";
import BaseInput from "@/components/common/BaseInput.vue";
import BaseButton from "@/components/common/AntDesign/BaseButton.vue";
import PatientLocationInfo from "@/components/common/PatientLocationInfo.vue";
import ProductSignIn from "@/components/marketplace/product/ProductSignIn.vue";
import ApplyRegistrationModal from "./ProductModal/ApplyRegistrationModal.vue";

export default {
  name: "ProductAddToCartFromList",
  components: {
    BaseInput,
    BaseButton,
    PatientLocationInfo,
    ApplyRegistrationModal,
    ProductSignIn,
  },
  props: {
    contentColor: {
      type: String,
      default() {
        return "";
      },
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      productLoading: false,
      patientLocation: false,
      cartProducts: [],
      userType: "",
      quantity: 1,
      isNotify: false,
      applyRegistrationModalEnabled: false,
      requireRegistration: false,
    };
  },
  computed: {
    ...mapGetters({
      getAddToCartQty: "marketplaceProduct/getAddToCartQty",
      getLocationId: "getLocationId",
      getCurrentUser: "getCurrentUser",
      isLoggedIn: "isLoggedIn",
      getOrgId: "getOrgId",
    }),
    ...mapGetters("locationsModule", ["getCurrentLocationSelected"]),
    ...mapGetters("usersModule", ["isLoggedIn"]),
    maxAvailable() {
      if (this.product.limited) {
        return this.product.limitedQty;
      }
      return 99999;
    },
    isDisabled() {
      if (
        this.quantity < this.product.minQtyForPurchase ||
        (this.product.limitedQty && this.quantity > this.maxAvailable) ||
        !this.product.inStock
      ) {
        return true;
      }
      return false;
    },
    buttonText() {
      if (this.product.isPreorder) return "Pre-Order";
      if (!this.product.inStock) return "Notify me";
      return "Add to cart";
    },
  },
  async mounted() {
    const registrationApproved = await this.getVendorApprovedRegistrationStatus(this.product.vendorId);
    this.requireRegistration = this.product.registrationRequired && !registrationApproved;
  },
  created() {
    this.quantity = this.product.minQtyForPurchase;
  },
  methods: {
    ...mapActions({
      commitQty: "marketplaceProduct/commitSetAddToCartQty",
      addToCartForList: "marketplaceProduct/addToCartForList",
      setEmptyCart: "setEmptyCart",
      commitItemToCart: "marketplaceProduct/commitItemToCart",
      subscribe: "marketplaceProduct/subscribeToNotifications",
      getVendorApprovedRegistrationStatus: "locationRegistrations/getVendorApprovedRegistrationStatus",
    }),
    ...mapActions("ordersModule", ["addPreOrderToCart"]),
    toggleAddLocation() {
      this.patientLocation = !this.patientLocation;
    },
    async addProductToPreOrderCart() {
      this.productLoading = true;
      const orgId = this.getOrgId;
      const locationId = this.getCurrentLocationSelected;

      await this.addPreOrderToCart({
        orgId,
        locationId,
        payload: {
          productId: this.product.id,
          qty: this.quantity,
        },
      });
      this.productLoading = false;
    },
    async addItemToCart() {
      if (this.product.isPreorder) {
        await this.addProductToPreOrderCart();
        return;
      } else if (!this.product.inStock) {
        await this.subscribe({
          productId: this.product.id,
        });
        this.isNotify = true;
      } else {
        const decodedToken = jwtdecode(localStorage.getItem("id_token"));
        const { userType } = decodedToken.sim.user;

        const isSimpatraSuperAdmin = get(decodedToken.sim, "simpatraAdmin", false);

        // This is the org id of the current login, this is for security purpose
        const orgId = !isSimpatraSuperAdmin ? get(decodedToken.sim.details, "id", null) : localStorage.getItem("orgId");

        this.productLoading = true;
        if (userType === "practiceUser" && !this.isLoggedIn) {
          let productArrayString = VueCookies.get("productArrayString");
          if (productArrayString !== null) {
            this.cartProducts = JSON.parse(productArrayString);
          }

          const response = this.cartProducts.filter((product) => {
            return product.productId === this.product.id;
          });

          let productQty = 0;

          if (response.length > 0) {
            productQty = response[0].qty + this.getAddToCartQty;
            this.cartProducts = this.cartProducts.filter((product) => {
              return product.productId !== response[0].productId;
            });
          } else {
            productQty = this.getAddToCartQty;
          }

          const data = {
            productId: this.product.id,
            productName: this.product.name,
            price: this.product.price,
            qty: productQty,
            id: this.product.id,
          };

          this.cartProducts.push(data);
          const cartProductsString = JSON.stringify(this.cartProducts);
          VueCookies.set("productArrayString", cartProductsString);
          productArrayString = VueCookies.get("productArrayString");
          const getItems = JSON.parse(productArrayString);
          await this.commitItemToCart(getItems);
          await this.setEmptyCart(false);
        } else {
          const locationId = this.getCurrentLocationSelected;
          const payload = {
            productId: this.product.id,
            qty: this.quantity,
            locationId,
            orgId,
          };
          await this.addToCartForList(payload)
            .then(async () => {
              await this.setEmptyCart(false);
              this.quantity = this.product.minQtyForPurchase;
              this.productLoading = false;
              Message({
                type: "success",
                showClose: true,
                message: "Item successfully added to cart",
              });
            })
            .catch((e) => {
              this.quantity = this.product.minQtyForPurchase;
              this.productLoading = false;
              Message({
                type: "error",
                showClose: true,
                message: e,
              });
            });
        }
      }
    },
    applyRegistrationModalToggle() {
      this.applyRegistrationModalEnabled = !this.applyRegistrationModalEnabled;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-to-cart-list {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-right: 23px;
}

.apply-registration-btn {
  display: flex;
  margin: 0;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.add-item-success {
  background-color: #e8f8cd;
  color: #67c23a;
  margin-bottom: 30px;
  padding: 10px;
  width: 300px;
  border-radius: 5px;
}
.add-to-cart-form {
  display: flex;
}
.label {
  font-size: 0.8em;
  margin-bottom: 5px;
}
.inventory {
  margin-bottom: 15px;
}
.warning {
  font-weight: 600;
  color: $alert-red;
}
.input {
  width: 100px;
  margin-right: 10px;
}

.add-notify-button {
  width: 95px;
}

.success {
  color: $success-green;
  font-size: 8px;
}

@media screen and (max-width: 425px) {
  .add-to-cart-form {
    width: 100%;
    .input,
    .submit {
      width: 50%;
    }

    .submit button {
      min-width: 100%;
    }
  }
}
</style>
